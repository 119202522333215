<template>
  <v-dialog
    v-model="isOpen"
    transition="slide-y-transition"
    max-width="638"
    content-class="add-ticket-modal"
    @click:outside="closeModal"
  >
    <div class="header-wrapper">
      <div class="top-banner">
        <span class="modal-title">Create Card</span>
        <button class="close-button" @click="closeModal">
          <img src="/img/icons/plus.svg" alt="">
        </button>
      </div>
      <div class="modal-header">
        <h3 class="modal-header-title">
          Required Fields
        </h3>
        <div class="modal-header-content">
          <AppSelect
            :value.sync="ticket.currentPhase"
            :items="columns"
            :error-messages="phaseError"
            is-required
            label="Current Phase:"
            @blur="this.$v.ticket.currentPhase.$touch"
          />
          <AppDatePicker
            label="on:"
            :date.sync="ticket.date"
            :date-error="dateError"
            @validate="this.$v.ticket.date.$touch"
          />
          <AppAutocomplete
            :value.sync="ticket.company"
            :error-messages="companyError"
            :items="getCompaniesList"
            label="Company"
            is-required
            @change="setCompanyId"
            @blur="this.$v.ticket.company.$touch"
          />
          <AppInput
            :value.sync="ticket.jobTitle"
            :error-messages="jobTitleError"
            label="Job Title"
            type="text"
            is-required
            @blur="this.$v.ticket.jobTitle.$touch"
          />
          <AppSelect
            item-text="title"
            item-id="value"
            :value.sync="ticket.colorLabel"
            :items="priorityList"
            label="Desirability Score *"
          />
        </div>
      </div>
      <span
        class="show-additional-info-button"
        @click="isShowAdditional = !isShowAdditional"
      >Additional Information here
        <v-icon v-if="isShowAdditional">mdi-chevron-up</v-icon>
        <v-icon v-else>mdi-chevron-down</v-icon></span>

      <ul v-if="isShowAdditional" class="modal-breadcrumbs">
        <li
          v-for="breadcrumb in breadcrumbs"
          :key="breadcrumb.id"
          class="breadcrumb-item"
          :class="{ active: currentStep === breadcrumb.id }"
          @click="changeStepByBreadcrumb(breadcrumb.id)"
        >
          {{ breadcrumb.title }}
        </li>
      </ul>
    </div>
    <div v-if="isShowAdditional" class="modal-main">
      <transition
        name="slide"
        enter-active-class="animated-transition"
        :enter-class="transitionEnterClass"
      >
        <component
          :is="activeComponent"
          :is-modal-open="isAddTicketModalOpen"
          :ticket.sync="ticket"
          @addNote="addNote"
        />
      </transition>
    </div>
    <div class="modal-footer">
      <div class="steps-controls">
        <AppButton
          v-if="currentStep > 1"
          width="165"
          height="34"
          outlined
          color="var(--button-primary-color)"
          class="prev-button"
          @click="backStep"
        >
          Back
        </AppButton>
        <AppButton
          :loading="isButtonDisabled"
          width="165"
          height="34"
          dark
          color="var(--button-primary-color)"
          class="create-ticket-button"
          @click="createTicket"
        >
          Save & Close
        </AppButton>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import TicketModalJobInfo from '@/components/TicketModalJobInfo'
import TicketModalFilesContacts from '@/components/TicketModalFilesContacts'
import TicketModalNotesHistory from '@/components/TicketModalNotesHistory'

export default {
  name: 'AddTicketModal',
  components: {
    TicketModalJobInfo,
    TicketModalFilesContacts,
    TicketModalNotesHistory
  },
  mixins: [validationMixin],
  props: {
    isAddTicketModalOpen: {
      type: Boolean,
      required: true
    },
    column: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      isShowAdditional: false,
      columns: [
        'Wishlist',
        'Applied',
        'Screening',
        'Interview I',
        'Interview II',
        'Accepted',
        'Offer',
        'Rejected'
      ],
      priorityList: [
        {
          id: 1,
          title: 'Highly desirable but unlikely to happen',
          value: 'first'
        },
        {
          id: 2,
          title: 'Highly desirable',
          value: 'second'
        },
        {
          id: 3,
          title: 'Hopeful to get this job',
          value: 'third'
        },
        {
          id: 4,
          title: 'Not my top choice but will consider an offer',
          value: 'fourth'
        },
        {
          id: 5,
          title: 'Applying just in case',
          value: 'fifth'
        }
      ],
      ticket: {
        colorLabel: '',
        company: '', // TODO rename to companyName (and do for ticket.company usage)
        companyId: 0,
        contacts: [
          {
            name: '',
            email: '',
            linkedin: '',
            communication: ''
          }
        ],
        coverLetter: '',
        currentPhase: this.column.title,
        date: '',
        id: 0,
        industry: '',
        jobCategory: '',
        jobLocation: {
          address: '',
          lat: 0,
          lng: 0
        },
        jobPostingLink: '',
        jobTitle: '',
        position: 0,
        postingText: '',
        resume: '',
        status: ''
      },
      breadcrumbs: [
        { id: 1, title: 'Job Info' },
        { id: 2, title: 'Files and Contacts' },
        { id: 3, title: 'Notes and History' }
      ],
      currentStep: 1,
      transitionEnterClass: '',
      isButtonDisabled: false,
      isModalClosing: false,
      ticketNote: null
    }
  },
  validations: {
    ticket: {
      currentPhase: {
        required
      },
      date: {
        required
      },
      company: {
        required
      },
      jobTitle: {
        required
      }
    }
  },
  mounted() {
    this.ticket.colorLabel = this.priorityList[2].value
  },
  computed: {
    ...mapGetters(['getCompaniesList']),
    activeComponent() {
      if (this.currentStep === 2) {
        return 'TicketModalFilesContacts'
      }

      if (this.currentStep === 3) {
        return 'TicketModalNotesHistory'
      }

      return 'TicketModalJobInfo'
    },
    phaseError() {
      if (this.isModalClosing) return
      const errors = []
      if (!this.$v.ticket.currentPhase.$dirty) return errors
      !this.$v.ticket.currentPhase.required && errors.push('Phase is required')
      return errors
    },
    dateError() {
      if (this.isModalClosing) return
      const errors = []
      if (!this.$v.ticket.date.$dirty) return errors
      !this.$v.ticket.date.required && errors.push('On date is required')
      return errors
    },
    companyError() {
      if (this.isModalClosing) return
      const errors = []
      if (!this.$v.ticket.company.$dirty) return errors
      !this.$v.ticket.company.required && errors.push('Company is required')
      return errors
    },
    jobTitleError() {
      if (this.isModalClosing) return
      const errors = []
      if (!this.$v.ticket.jobTitle.$dirty) return errors
      !this.$v.ticket.jobTitle.required && errors.push('Job title is required')
      return errors
    }
  },
  watch: {
    isAddTicketModalOpen(value) {
      this.isOpen = value
      this.isModalClosing = !value
      this.$v.$reset()
    },
    currentStep(newVal, oldVal) {
      this.transitionEnterClass =
        newVal > oldVal ? 'slide-in-right' : 'slide-in-left'
    }
  },
  methods: {
    closeModal() {
      this.isModalClosing = true
      this.currentStep = 1
      this.ticket = {
        colorLabel: '',
        company: '', // TODO rename to companyName (and do for ticket.company usage)
        companyId: 0,
        contacts: [
          {
            name: '',
            email: '',
            linkedin: '',
            communication: ''
          }
        ],
        coverLetter: '',
        currentPhase: this.column.title,
        date: '',
        id: 0,
        industry: '',
        jobCategory: '',
        jobLocation: {
          address: '',
          lat: 0,
          lng: 0
        },
        jobPostingLink: '',
        jobTitle: '',
        position: 0,
        postingText: '',
        resume: '',
        status: ''
      }
      this.$emit('update:isAddTicketModalOpen', false)
    },
    checkValidationFail() {
      this.$v.$touch()
      return this.$v.$invalid
    },
    backStep() {
      this.currentStep -= 1
    },
    nextStep() {
      if (this.checkValidationFail()) return
      this.currentStep += 1
    },
    changeStepByBreadcrumb(breadcrumbId) {
      this.currentStep = breadcrumbId
    },
    async createTicket() {
      if (this.checkValidationFail()) return

      this.isButtonDisabled = true
      this.ticket.position = this.getTicketPosition()

      const { error } = await api.users.createTicket(this.ticket)

      if (!error) {
        this.isButtonDisabled = false
        this.$emit('resetTickets')
        this.closeModal()
        if (this.ticketNote) this.saveNote()
      }
    },
    getTicketPosition() {
      let position

      if (this.column.tickets.length) {
        position =
          Math.max.apply(
            Math,
            this.column.tickets.map((ticket) => ticket.position)
          ) + 65535
        return position
      }

      position = 65535
      return position
    },
    addNote(note) {
      this.ticketNote = note
    },
    async saveNote() {
      const { error } = await api.users.setTicketNote(
        this.ticketId,
        this.ticketNote
      )

      if (error) console.log(error)
    },
    setCompanyId(company) {
      if (!company) return

      this.ticket.company = company.name || company
      this.ticket.companyId = company.id || 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.v-dialog__content::v-deep .add-ticket-modal {
  @include default-modal-wrapper;
}

.header-wrapper {
  position: sticky;
  top: 0;
  z-index: 5;
}

.top-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: var(--border-radius-default);
  border-top-right-radius: var(--border-radius-default);
  padding: 15px 25px;
  background-color: var(--background-primary-color);

  @include breakpoint-reverse(small) {
    padding: 5px 12px;
  }
}

.modal-title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h3);
  color: var(--contrast-text-color);
}

.show-additional-info-button {
  cursor: pointer;
  padding: 10px 25px;
  display: block;
}

.close-button {
  position: absolute;
  right: 15px;
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
}

.modal-header {
  padding: 18px 25px 30px;
  background-color: var(--background-modal-color);

  @include breakpoint-reverse(small) {
    padding: 5px 12px;
  }

  .modal-header-title {
    font-size: var(--font-size-base-lg);
    font-weight: 600;
    color: #344054;
  }

  .modal-header-content {
    display: grid;
    column-gap: 15px;
    grid-template-columns: 50% 50%;
  }
}

@include default-modal-breadcrumbs;

.modal-main {
  @include default-modal-main;
}

.modal-footer {
  position: sticky;
  bottom: 0;
  z-index: 5;
  padding: 37px 25px;
  background-color: var(--background-modal-color);

  @include breakpoint-reverse(small) {
    padding: 12px;
  }

  .steps-controls {
    display: flex;
    align-items: center;

    @include breakpoint-reverse(small) {
      gap: 10px;

      & > .app-button {
        width: fit-content !important;
      }
    }
  }

  .prev-button,
  .next-button,
  .create-ticket-button {
    &::v-deep .text {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-sub);
      line-height: var(--line-height-xs);
      text-shadow: none;
    }
  }

  .create-ticket-button,
  .next-button {
    margin-left: auto;

    &::v-deep .text {
      color: var(--primary-button-text-color);
    }
  }
}
</style>
