<template>
  <div
    :class="[
      'ticket',
      `priority-${ticket.colorLabel}`,
      { shelved: ticket.status === 'shelved' },
    ]"
    @click="openUpdateTicketModal"
  >
    <span class="company">{{ ticket.company }}</span>
    <span class="job-title">{{ ticket.jobTitle }}</span>
    <span class="date"> <b>{{ descriptionDaysAgo }}</b> in {{ columnTitle }} // {{ descriptionDate }}</span>

    <div class="additional-info">
      <div class="info-item">
        <img src="/img/icons/comment.svg" alt="comments"> {{ commentsAmount }} comments
      </div>
      <div class="info-item">
        <img src="/img/icons/file.svg" alt="files"> {{ filesAmount }} files
      </div>
    </div>
  </div>
</template>

<script>
import { formatISOdate } from '@/services/dateService'

export default {
  name: 'DashboardColumnTicket',
  props: {
    ticket: {
      type: Object,
      required: true
    },
    columnTitle: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      ticketDate: this.ticket.date
    }
  },
  computed: {
    descriptionDate() {
      return ['Wishlist', 'Applied'].includes(this.columnTitle)
        ? `created ${formatISOdate(this.ticket.createDate)}`
        : `${this.columnTitle} date ${formatISOdate(this.ticket.createDate)}`
    },
    descriptionDaysAgo() {
      return `${this.getDiffDays(new Date(), new Date(this.ticket.dateUpdatePhase))} days `
    },
    filesAmount() {
      let amount = 0
      if (this.ticket.coverLetter) amount++
      if (this.ticket.resume) amount++
      return amount
    },
    commentsAmount() {
      // TODO: Ask about comments
      return 0
    }
  },
  methods: {
    formatISOdate,
    openUpdateTicketModal() {
      this.$emit('openUpdateTicketModal')
    },
    getDiffDays(date1, date2) {
      return Math.round((date1 - date2) / (1000 * 60 * 60 * 24))
    }
  }
}
</script>

<style lang="scss" scoped>
.ticket {
  cursor: pointer;
  display: grid;
  border-radius: var(--border-radius-default);
  padding: 18px;
  height: fit-content;
  text-align: left;

  &.shelved {
    background-color: var(--shelved-ticket-bg-color) !important;
  }
}

.priority {
  &-first {
    background-color: var(--first-priority-color);
  }

  &-second {
    background-color: var(--second-priority-color);
  }

  &-third {
    background-color: var(--third-priority-color);
  }

  &-fourth {
    background-color: var(--fourth-priority-color);
  }

  &-fifth {
    background-color: var(--fifth-priority-color);
  }
}

.job-title {
  font-size: var(--font-size-sub);
}

.position {
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-base-lg);
}

.date {
  font-size: var(--font-size-xsm);
  line-height: 18px;
  margin-bottom: 19px;
}

.additional-info {
  display: flex;
  justify-content: flex-end;
  column-gap: 6px;

  .info-item {
    font-size: var(--font-size-sm);
    display: flex;
    column-gap: 4px;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}
</style>
