<template>
  <div class="dashboard-column">
    <div class="column-header">
      <span class="column-title">
        {{ column.title }}
      </span>
      <span class="jobs-counter">
        {{ column.tickets.length === 1 ? `${column.tickets.length} job` : `${column.tickets.length} jobs` }}
      </span>
      <AppButton
        width="260"
        height="48"
        color="var(--background-primary-color)"
        class="add-ticket-button"
        @click="openAddTicketModal"
      >
        <img src="/img/icons/plus.svg" alt="">
      </AppButton>
    </div>
    <div class="column-body">
      <Draggable
        group="tickets"
        ghost-class="ghost-ticket"
        chosen-class="chosen-ticket"
        class="tickets-list"
        :data-id="column.id"
        :list="column.tickets"
        :move="setTicketId"
        @end="reviewHandler"
        @change="changePosition"
      >
        <DashboardColumnTicket
          v-for="(ticket, i) in column.tickets"
          :key="i"
          :ticket="ticket"
          :column-title="column.title"
          class="draggable-ticket"
          @updateTickets="$emit('updateTickets')"
          @openRateModal="openRateModal(ticket.id)"
          @openUpdateTicketModal="$emit('openUpdateTicketModal', ticket.id)"
        />
      </Draggable>
      <div v-if="column.tickets.length > 4">
        <v-icon large color="var(--default-icon-color)">
          mdi-menu-down
        </v-icon>
      </div>
    </div>
    <AddTicketModal
      :column="column"
      :is-add-ticket-modal-open.sync="isAddTicketModalOpen"
      @resetTickets="$emit('resetTickets')"
    />
    <RateModal
      :ticket="ticketToUpdate"
      :phase="column.title"
      :is-rate-modal-open.sync="isRateModalOpen"
      @resetTickets="$emit('resetTickets')"
    />
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { getNewPosition } from '@/utils/utils'
import api from '@/api'
import DashboardColumnTicket from '@/components/DashboardColumnTicket'
import AddTicketModal from '@/components//AddTicketModal'
import RateModal from '@/components/RateModal'

export default {
  name: 'DashboardColumn',
  components: {
    Draggable,
    DashboardColumnTicket,
    AddTicketModal,
    RateModal
  },
  props: {
    column: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ticketToUpdate: null,
      isAddTicketModalOpen: false,
      isRateModalOpen: false,
      ticketId: 0
    }
  },
  methods: {
    getNewPosition,
    setTicketId({ draggedContext }) {
      this.ticketToUpdate = this.column.tickets[draggedContext.index]
      this.ticketId = this.ticketToUpdate.id
    },
    reviewHandler(event) {
      if (
        event.from === event.to ||
        event.from.dataset.id > event.to.dataset.id ||
        this.column.title === 'Wishlist'
      ) return

      this.openRateModal()
    },
    openRateModal(ticketId) {
      if (ticketId) this.ticketId = ticketId
      this.isRateModalOpen = true
    },
    openAddTicketModal() {
      this.isAddTicketModalOpen = true
    },
    changePosition(e) {
      if (e.removed) return

      const eventAction = e.added ? e.added : e.moved
      const { newIndex } = eventAction
      this.ticketToUpdate = this.column.tickets[newIndex]

      this.ticketToUpdate.position = this.getNewPosition(this.column.tickets, eventAction)
      this.ticketToUpdate.currentPhase = this.column.title

      // if we move an inactive ticket from the wishlist,
      // we make it active, since the rest of the columns do not have a status switch and the ticket will be lost
      if (e.added) this.ticketToUpdate.status = 'active'

      this.updateTicket(newIndex)
    },
    updateTicket(newIndex) {
      this.column.tickets[newIndex] = this.ticketToUpdate
      api.users.updateTicket(this.ticketToUpdate.id, this.ticketToUpdate)
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-column {
  border-radius: var(--border-radius-default);
  padding: 21px 12px 13px;
  background-color: var(--background-secondary-color);
}

.column-header,
.column-body {
  text-align: center;
}

.column-title {
  display: block;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
}

.jobs-counter {
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-base-sm);
}

.add-ticket-button {
  margin-top: 28px;
  margin-bottom: 24px;
  box-shadow: none;

  &::v-deep span.text {
    height: 24px;
    line-height: 24px;
  }
}

.tickets-list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 475px;
  gap: 14px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.draggable-ticket {
  transition: opacity 0.3s, box-shadow 0.3s;

  &.ghost-ticket {
    opacity: 0.5;
  }

  &.chosen-ticket {
    box-shadow: var(--box-shadow-default);
  }
}
</style>
