<template>
  <v-dialog
    v-model="isOpen"
    max-width="684"
    content-class="dashboard-data-upload-dialog"
    @click:outside="$emit('close')"
  >
    <div class="dialog-header">
      <h3 class="dialog-title">
        Import your external job application data now
      </h3>
      <p class="dialog-subtitle">
        Generate a template from a link provided below, add your data, and import!
      </p>

      <button class="close-button" @click="$emit('close')">
        <img src="/img/icons/plus-black.svg" alt="">
      </button>
    </div>

    <div class="drag-and-drop-wrapper">
      <div
        class="drag-and-drop-area"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <div class="drag-and-drop-info">
          <img
            src="/img/drag-files-img.svg"
            height="109"
            width="109"
            class="file-img"
            alt=""
          >
          <span v-if="file" class="info-text">
            <button class="discard-file-btn" @click="discard">
              <v-icon x-small>
                mdi-close
              </v-icon>
            </button>
            {{ file. name }}
          </span>
          <p class="drag-and-drop-title">
            Drag CSV/XLSX here
          </p>
          <label for="fileField" class="input-label">or click to browse</label>
        </div>

        <input
          v-show="false"
          id="fileField"
          ref="fileField"
          type="file"
          accept=".csv,.xlsx"
          @change="handleFileUpload"
        >
      </div>
      <p class="info-text">
        Please use a CSV or XLS template below —>
        add your existing application data to the file —> save to your desktop —> then drag and drop
      </p>

      <div class="generate-actions">
        <a href="/import/example.xlsx" class="action">Generate XLSX Template</a>

        <a href="/import/example.csv" class="action">Generate CSV Template</a>
      </div>
    </div>

    <template v-if="uploadStatuses">
      <div v-for="(status, i) in uploadStatuses" :key="i" class="file-preview">
        <span class="file-name">{{ status.jobTitle }}</span>
        <span class="file-status">{{ status.status }}</span>
      </div>
    </template>

    <div class="dialog-actions">
      <div>
        <v-icon size="19" color="#9AA6AC">
          mdi-help-circle-outline
        </v-icon>
        <span class="support-text"> Please contact
          <a class="support-link" href="mailto:support@unghosted.co">support@unghosted.co</a> with any questions
        </span>
      </div>

      <button class="action-btn discard" @click="discard">
        Discard
      </button>
      <button class="action-btn import" @click="importFile">
        Import
      </button>
    </div>
  </v-dialog>
</template>

<script>
import api from '@/api'
export default {
  name: 'DashboardDataUploadDialog',
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isOpen: this.isDialogOpen,
      uploadStatuses: null,
      file: null
    }
  },
  watch: {
    isDialogOpen(value) {
      this.isOpen = value
    }
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.fileField.files[0]
    },
    async importFile() {
      const { data } = await api.users.importTickets(this.file)
      if (!data) return

      this.uploadStatuses = data
      this.$emit('fileImported')
    },
    dragover(event) {
      event.preventDefault()

      if (!event.currentTarget.classList.contains('drag-active')) {
        event.currentTarget.classList.add('drag-active')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('drag-active')
    },
    drop(event) {
      event.preventDefault()

      this.$refs.fileField.files = event.dataTransfer.files
      this.handleFileUpload()

      event.currentTarget.classList.remove('drag-active')
    },
    discard() {
      this.$refs.fileField.value = ''
      this.file = null
      this.uploadStatuses = null
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .dashboard-data-upload-dialog {
    padding: 46px 58px;
    background-color: var(--background-secondary-color);
    border-radius: 10px;
  }

  .dialog-header {
    position: relative;

    .dialog-title {
      margin-bottom: 9px;
      font-size: var(--font-size-lg);
    }

    .dialog-subtitle {
      color: #9AA6AC;
      font-weight: var(--font-weight-bold);
      letter-spacing: 0;
      font-size: 15px;
    }

    .close-button {
      position: absolute;
      top: 0;
      right: -20px;
      width: 24px;
      height: 24px;
      transform: rotate(45deg);
    }
  }

  .drag-and-drop-wrapper {
    margin: 48px 0 19px;

    .drag-and-drop-area {
      margin-bottom: 14px;
      padding: 32px;
      border: 2px dashed #3069FE;
      border-radius: var(--border-radius-default);

      &.drag-active {
        background-color: rgba(48, 105, 254, 0.1);
      }
    }

    .drag-and-drop-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .file-img {
      margin-bottom: 19px;
    }

    .drag-and-drop-title {
      margin-bottom: 7px;
      font-weight: var(--font-weight-bolder);
    }

    .input-label {
      font-size: 13px;
      text-decoration: underline;
      text-underline-offset: 3px;
      cursor: pointer;
    }

    .info-text {
      color: #9AA6AC;
      font-size: 13px;
      text-align: center;
    }
  }

  .file-preview {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    padding: 8px;
    border: 1px solid #DDE2E4;
    border-radius: 4px;

    .file-name {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-sm);
    }

    .file-status {
      color: #9AA6AC;
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-sm);
    }

    .discard-file-btn {
      position: absolute;
      top: 0;
      right: 8px;
    }
  }

  .generate-actions {
    display: flex;
    justify-content: space-between;

    .action {
      text-decoration: underline;
      color: #1458DD !important;
    }
  }

  .dialog-actions {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .support-text {
      color: #9AA6AC;
      font-weight: var(--font-weight-bolder);
      font-size: var(--font-size-base-sm);
    }

    .support-link {
      text-decoration: underline;
      color: #1458DD !important;
    }

    .action-btn {
      padding: 14px 26px;
      font-weight: var(--font-weight-bolder);
      font-size: var(--font-size-base-sm);
      border-radius: 6px;

      &.discard {
        margin-right: 13px;
        border: 1px solid #DDE2E4;
      }

      &.import {
        color: var(--contrast-text-color);
        background-color: #1458DD;
      }
    }
  }
}
</style>
