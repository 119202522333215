<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-top-transition"
    max-width="502"
    content-class="rate-modal"
    overlay-color="var(--overlay-bg-color)"
    overlay-opacity="1"
    @click:outside="closeModal"
  >
    <v-progress-circular
      v-if="loading"
      class="loader-main"
      size="50"
      width="5"
      indeterminate
    />
    <template v-else>
      <div class="rate-modal-header">
        <h3 class="rate-modal-title">
          Congratulations on the next step!
        </h3>
        <div class="date-block">
          <h4 class="date-title">
            Your <span class="phase">{{ ticket.currentPhase }}</span> date is:
          </h4>
          <AppDatePicker
            :date.sync="ticketDate"
            :date-error="dateErrors"
            @validate="this.$v.ticketDate.$touch"
          />
        </div>
      </div>
      <div class="rate-modal-body">
        <h3 v-if="!isAlreadyRated" class="rate-modal-title">
          Before we move to the next stage, rate your overall experience in the <strong>{{ phase }}</strong>
          stage for this specific application:
        </h3>
        <h3 v-else class="rate-modal-title">
          You already rated this experience form <strong>{{ phase }}</strong>
          to <strong>{{ ticket.currentPhase }}</strong>.
          Would you like to change it?
        </h3>
        <button class="close-button" @click="closeModal">
          <img src="/img/icons/plus-black.svg" alt="">
        </button>
        <span class="rate-modal-text">Note: 5 stars is the highest possible rating for this stage.</span>
        <v-rating
          v-model="rating"
          hide-details
          color="var(--contrast-text-color)"
          background-color="var(--contrast-text-color)"
          class="rating"
        />
        <span class="rate-modal-text">
          In order for UnGosted to operate fairly and deliver value to all job seekers, we rely on you and your effort.
          Thank you for your honesty.
        </span>
        <AppButton
          :loading="isButtonDisabled"
          width="165"
          height="34"
          dark
          color="var(--button-primary-color)"
          class="save-button"
          @click="savePhaseRateAndDate"
        >
          Save
        </AppButton>
      </div>
    </template>
  </v-dialog>
</template>

<script>
import api from '@/api'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  name: 'RateModal',
  mixins: [validationMixin],
  props: {
    ticket: {
      type: Object,
      required: false,
      default: () => {}
    },
    phase: {
      type: String,
      required: true
    },
    isRateModalOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      isOpen: false,
      rating: 0,
      isButtonDisabled: false,
      ticketDate: '',
      dateErrors: [],
      isAlreadyRated: false,
      phasesRates: null
    }
  },
  validations: {
    ticketDate: {
      required
    }
  },
  watch: {
    isRateModalOpen(value) {
      this.isOpen = value

      if (this.isOpen) {
        this.resetModal()
        this.fetchTicketRate()
      }
    }
  },
  methods: {
    validation() {
      this.$v.$touch()
      if (!this.$v.ticketDate.$dirty) return this.dateErrors
      !this.$v.ticketDate.required &&
      this.dateErrors.push('Date is required')

      return !this.$v.$invalid
    },
    closeModal() {
      this.$emit('update:isRateModalOpen', false)
      this.$emit('resetTickets')
    },
    updateTicket() {
      this.ticket.date = this.ticketDate
      api.users.updateTicket(this.ticket.id, this.ticket)
    },
    usePhaseRate() {
      const currentPhase = this.phasesRates.find(({ phase }) => phase === this.phase)

      if (currentPhase) {
        this.isAlreadyRated = true
        this.rating = currentPhase.rate

        return
      }

      this.resetModal()
    },
    async fetchTicketRate() {
      const { data } = await api.users.getTicketRate(this.ticket.id)

      this.loading = false

      this.phasesRates = data || null

      if (this.phasesRates) this.usePhaseRate()
    },
    async savePhaseRateAndDate() {
      if (!this.validation()) return

      const { error } = await api.users.setPhaseRating(this.ticket.id, this.phase, this.rating)
      this.updateTicket()

      if (!error) this.closeModal()
    },
    resetModal() {
      this.ticketDate = ''
      this.dateErrors = []
      this.isAlreadyRated = false
      this.rating = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep .rate-modal {
  position: relative;
  border-radius: var(--border-radius-default);
  background-color: var(--background-secondary-color);
}

.rate-modal-header {
  padding: 35px;
  background-color: var(--background-secondary-color);

  .date-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    gap: 8px;
  }

  .date-title {
    margin-bottom: 4px;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-sub);

    .phase {
      position: relative;
      z-index: 1;
      margin: 0 5px;
      font-weight: var(--font-weight-bold);

      &::after {
        content: "";
        position: absolute;
        left: -6px;
        top: 25%;
        z-index: -1;
        display: block;
        width: calc(100% + 12px);
        height: 15px;
        background-color: var(--background-tertiary-color);
        transform: translateY(25%);
      }
    }
  }

  &::v-deep .app-input {
    width: 130px;
  }
}

.rate-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 65px 26px;
  background-color: var(--rate-modal-bg-color);
}

.rate-modal-title {
  font-weight: 500;
  font-size: var(--font-size-h3);
  line-height: 24px;
  text-align: center;
  margin-bottom: 15px;
}

.close-button {
  position: absolute;
  right: 14px;
  top: 14px;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);

  img {
    width: 15px;
    height: 15px;
  }
}

.rating {
  display: flex;
  justify-content: center;
  margin: 5px auto;
}

.rate-modal-text {
  display: block;
  max-width: 350px;
  font-weight: var(--font-weight-light);
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #6F6F6F;
}

.save-button {
  margin-top: 18px;
}

.save-button::v-deep .text {
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-sub);
  line-height: var(--line-height-xs);
  text-shadow: none;
}
</style>
