export const getNewPosition = (ticketsArray, action) => {
  if (!action) return

  const newPositionIndex = action.newIndex
  const optimalTicketInitialPosition = 65535

  let newPosition

  // if we add ticket at start
  if (newPositionIndex === 0) {
    // subtract from the length of the array element that we added
    if (ticketsArray.length - 1) {
      // if the array was not empty before adding => get minimum position value in array and divide by 2
      newPosition = parseFloat(Math.min.apply(Math, ticketsArray
        // filter added item from array to get the values of only the elements that were before adding
        .filter((_, index) => index !== newPositionIndex)
        .map((el) => el.position)) / 2)

      return newPosition
    }

    // if the element that we added was the first one, we assign it a optimal initial position
    newPosition = optimalTicketInitialPosition
    return newPosition
  }

  // if we placed ticket between two existing tickets
  if (newPositionIndex < (ticketsArray.length - 1)) {
    const firstSiblingOrder = parseFloat(ticketsArray[newPositionIndex - 1].position)
    const secondSiblingOrder = parseFloat(ticketsArray[newPositionIndex + 1].position)

    // just use the average of two adjacent array elements as the new position
    newPosition = (firstSiblingOrder + secondSiblingOrder) / 2

    return newPosition
  }

  // if we moved ticket at the end
  if (newPositionIndex >= (ticketsArray.length - 1)) {
    // get maximum position value in array
    const maximumPosition = parseFloat(Math.max.apply(Math, ticketsArray
      // filter added item from array to get the values of only the elements that were before adding
      .filter((_, index) => index !== newPositionIndex)
      .map((el) => el.position)))

    // add to it a buffer which is the optimal initial position
    newPosition = maximumPosition + optimalTicketInitialPosition
    return newPosition
  }
}

export const debounce = (func, delay = 300) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
