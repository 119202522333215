<template>
  <section class="dashboard">
    <PageHeader>
      Dashboard

      <template #action>
        <AppButton
          dark
          color="#1458DD"
          class="import-data-btn"
          @click="isShowUploadDialog = true"
        >
          Import Data
        </AppButton>
      </template>
    </PageHeader>

    <DashboardDataUploadDialog
      :is-dialog-open.sync="isShowUploadDialog"
      @fileImported="getTickets"
      @close="isShowUploadDialog = false"
    />

    <div class="banner-block">
      <span class="banner-title">Ghostability at a Glance</span>
      <span class="banner-subtitle">Focus on the right job(s).</span>
      <div class="banner">
        We’re building data visualization tools to help prevent that dead silence known as Ghosting.
        <br>
        <br>
        Suggest preferred widgets
        <a href="/help-contact" class="link">here</a>!
      </div>
    </div>
    <section class="content">
      <div class="content-header">
        <span class="dashboard-subtitle">Track your job applications here.</span>
        <span class="legend-title">Colors will remind you how desirable each card is:</span>
        <ul class="legend-colors-list">
          <li
            v-for="priority in priorityList"
            :key="priority.id"
            class="color-item"
          >
            <div :class="['color-priority', `${priority.value}`]" />
            <span>{{ priority.title }}</span>
          </li>
        </ul>
        <v-icon size="50" color="var(--default-icon-color)" class="scroll-btn-icon">
          mdi-chevron-right
        </v-icon>
      </div>
      <div class="dashboard-columns">
        <DashboardColumn
          v-for="column in dashboardColumns"
          :key="column.id"
          :column="column"
          @resetTickets="resetTickets"
          @updateTickets="resetTickets"
          @openUpdateTicketModal="openUpdateTicketModal"
        />
      </div>
    </section>
    <UpdateTicketModal
      :ticket-id="ticketIdForUpdateModal"
      :is-update-ticket-modal-open.sync="isUpdateTicketModalOpen"
      @updateTickets="resetTickets"
    />
  </section>
</template>

<script>
import api from '@/api'
import { mapGetters, mapActions } from 'vuex'
import DashboardColumn from '@/components/DashboardColumn'
import UpdateTicketModal from '@/components/UpdateTicketModal'
import DashboardDataUploadDialog from '@/components/DashboardDataUploadDialog.vue'

export default {
  name: 'Dashboard',
  components: {
    DashboardDataUploadDialog,
    DashboardColumn,
    UpdateTicketModal
  },
  data() {
    return {
      isShowUploadDialog: false,
      dashboardColumns: [
        {
          id: 1,
          title: 'Wishlist',
          tickets: []
        },
        {
          id: 2,
          title: 'Applied',
          tickets: []
        },
        {
          id: 3,
          title: 'Screening',
          tickets: []
        },
        {
          id: 4,
          title: 'Interview I',
          tickets: []
        },
        {
          id: 5,
          title: 'Interview II',
          tickets: []
        },
        {
          id: 6,
          title: 'Accepted',
          tickets: []
        },
        {
          id: 7,
          title: 'Offer',
          tickets: []
        },
        {
          id: 8,
          title: 'Rejected',
          tickets: []
        }
      ],
      priorityList: [
        {
          id: 1,
          title: 'Highly desirable but unlikely to happen',
          value: 'first'
        },
        {
          id: 2,
          title: 'Highly desirable',
          value: 'second'
        },
        {
          id: 3,
          title: 'Hopeful to get this job',
          value: 'third'
        },
        {
          id: 4,
          title: 'Not my top choice but will consider an offer',
          value: 'fourth'
        },
        {
          id: 5,
          title: 'Applying just in case',
          value: 'fifth'
        }
      ],
      loadTicketsParams: {
        filterByPhase: '',
        filterByStatus: ''
      },
      tickets: [],
      ticketIdForUpdateModal: 0,
      isUpdateTicketModalOpen: false
    }
  },
  computed: {
    ...mapGetters(['getCompaniesList'])
  },
  created() {
    this.getTickets()
    if (!this.getCompaniesList.length) this.fetchCompaniesList()
  },
  methods: {
    ...mapActions(['setCompaniesList']),
    async getTickets() {
      const { data } = await api.users.getTickets(this.loadTicketsParams)

      if (data?.length) {
        this.tickets = data.filter(ticket => ticket.status !== 'archived')
        this.placeTicketsInColumns()
      }
    },
    async fetchCompaniesList() {
      const { data } = await api.users.getCompanies()
      if (data) this.setCompaniesList([{ name: 'Other' }, ...data])
    },
    resetTickets() {
      this.tickets = []
      this.dashboardColumns.forEach((column) => {
        column.tickets = []
      })

      this.getTickets()
    },
    placeTicketsInColumns() {
      this.tickets.forEach((ticket) => {
        const columnForTicket = this.dashboardColumns.find((column) => column.title === ticket.currentPhase)
        const isTicketAlreadyAdded = columnForTicket.tickets.find((columnTicket) => columnTicket.id === ticket.id)

        if (isTicketAlreadyAdded) return

        columnForTicket.tickets.push(ticket)
      })
    },
    openUpdateTicketModal(ticketId) {
      this.ticketIdForUpdateModal = ticketId
      this.isUpdateTicketModalOpen = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.dashboard {
  overflow-x: scroll;
  @include default-page-paddings;

  .import-data-btn {
    box-shadow: none;

    &::v-deep .text {
      font-weight: var(--font-weight-bolder);
      font-size: var(--font-size-sub);
      line-height: var(--line-height-xs);
      text-shadow: none;
    }
  }
}

.content {
  margin-top: 20px;
  margin-left: 50px;

  @include breakpoint-reverse(medium) {
    margin-left: 0;
  }

  @include breakpoint-reverse(small) {
    margin-top: 20px;
  }
}

.content-header {
  display: flex;
  flex-direction: column;
}

.dashboard-subtitle,
.legend-title {
  display: block;
  margin-bottom: 5px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sub);
}

.legend-title {
  font-size: var(--font-size-sm);
}

.legend-colors-list {
  display: grid;
  padding: 0;
}

.color-item {
  display: flex;
  align-items: center;
  color: var(--secondary-text-color);
  font-size: var(--font-size-sm);
}

.color-priority {
  margin-right: 5px;
  border: 1px solid var(--primary-border-color);
  border-radius: 50%;
  width: 14px;
  height: 14px;

  &.first {
    background-color: var(--first-priority-color);
  }

  &.second {
    background-color: var(--second-priority-color);
  }

  &.third {
    background-color: var(--third-priority-color);
  }

  &.fourth {
    background-color: var(--fourth-priority-color);
  }

  &.fifth {
    background-color: var(--fifth-priority-color);
  }
}

.scroll-btn-icon {
  position: absolute;
  right: 35px;

  @include breakpoint-reverse(small) {
    right: 0;
  }
}

.banner-block {
  position: absolute;
  top: 37px;
  right: 50%;
  transform: translateX(50%);

  @media (max-width: 1600px) {
    right: 40%;
    max-width: 550px;
  }

  @media (max-width: 1300px) {
    right: 50%;
    max-width: 500px;
  }

  @media (max-width: 1250px) {
    display: none;
  }

  .banner-title {
    position: relative;
    z-index: 1;
    display: block;
    width: fit-content;
    margin-bottom: 20px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xlg);

    &::after {
      content: "";
      position: absolute;
      left: -12px;
      top: 25%;
      z-index: -1;
      display: block;
      width: calc(100% + 24px);
      height: 24px;
      background-color: var(--background-highlight-color);
      transform: translateY(25%);
    }
  }

  .banner-subtitle {
    display: block;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sub);
  }

  .banner {
    position: relative;
    z-index: 1;
    max-width: 400px;
    margin-top: 15px;
    padding: 12px 25px;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-base-sm);
    text-align: center;
    background-color: var(--shelved-ticket-bg-color);
    border-radius: var(--border-radius-default);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      border-radius: var(--border-radius-default);
      background-image: url('/img/banner-bg.png');
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }

    .link {
      text-decoration: underline;
    }
  }
}

.dashboard-columns {
  display: grid;
  grid-template-columns: repeat(8, 284px);
  margin-top: 40px;
  padding-right: 30px;
  width: fit-content;
  gap: 30px;
}
</style>
